export default {
  possibleTypes: {
    AccordionListItemContent: ['PageContentTextSection', 'PaxTimeTable'],
    AppTerminalInfoMapRef: ['MapBlock', 'MapLayout'],
    BioPromoResume: ['AssetLink', 'Link', 'Url'],
    ChartSetChartsItem: ['LineChart', 'PeopleChart'],
    ContentRowContentEntryItem: [
      'BafChargeBlock',
      'BafChargesList',
      'BioPromo',
      'ChartSet',
      'ContactBox',
      'ExternalWidget',
      'FootballCalendar',
      'FreightRoutesListBox',
      'FreightSchedule',
      'GhsPictogram',
      'HazardousCargo',
      'IconBlockList',
      'IframeBlock',
      'InfoBlock',
      'LinkboxCarousel',
      'LocalisationWrapper',
      'MapBlock',
      'MapLayout',
      'MediaLibraryEntry',
      'MiniBook',
      'NewsArticleList',
      'NewsletterSubscribeBlock',
      'NewsletterUnsubscribeBlock',
      'PageContentAccordionList',
      'PageContentTextSection',
      'PageContentTextWithLanguageSwitcher',
      'PaxProductCard',
      'PaxTimeTable',
      'PromoBlock',
      'ShareChartSet',
      'SharePriceBlock',
      'StackingIconBox',
      'TextAndMediaLayout',
      'TourOperatorWidget',
      'TripAtAGlance',
      'Trustpilot',
      'TwitterWidget',
      'XSpaceRefComponent',
    ],
    Entry: [
      'AccordionListItem',
      'AppLink',
      'AppRoute',
      'AppTerminalInfo',
      'AssetLink',
      'BafCharge',
      'BafChargeBlock',
      'BafChargesList',
      'BioPromo',
      'BusinessUnit',
      'CabinPage',
      'ChartSet',
      'ContactBox',
      'ContactContent',
      'ContentRow',
      'CountryPage2Gmr',
      'DropdownEntry',
      'EmailSenderCopyTemplate',
      'EmailTemplate',
      'EnquiryFormLocation',
      'ExperienceCardContent',
      'ExperienceCardFeature',
      'ExternalWidget',
      'FaqSection',
      'FerryCrossingOnboardExperienceOverviewPage',
      'FerryCrossingOnboardExperienceRelatedPage',
      'FerryCrossingProductPage',
      'FilterLocale',
      'FootballCalendar',
      'FootballMatch',
      'FootballSchedule',
      'FootballTeam',
      'FootballTeamPage',
      'FormContactUs',
      'FormForgetMe',
      'FormGroupBooking',
      'FreightArrivalsAndDepartures',
      'FreightRouteList',
      'FreightRoutesListBox',
      'FreightSchedule',
      'FreightScheduleContent',
      'FreightScheduleList',
      'FreightScheduleTableDaily',
      'FreightScheduleTableFrequent',
      'FreightScheduleTableSeasonal',
      'FreightSeasonSchedule',
      'GdprCheckboxLabel',
      'GdprSettings',
      'GeneralShortText',
      'GenericPage',
      'GhsPictogram',
      'HazardousCargo',
      'HomePage',
      'IconBlock',
      'IconBlockList',
      'IconListIcon',
      'IframeBlock',
      'InfoBlock',
      'InfoContent',
      'LibraryPage',
      'LightboxWrapper',
      'LineChart',
      'Link',
      'LinkboxCarousel',
      'LinkboxCarouselItem',
      'LinkboxItemContent',
      'LiveFeedPage',
      'LiveQuarterlyReport',
      'LocalisationWrapper',
      'LocalizedRow',
      'LoyaltyBenefits',
      'MapBlock',
      'MapContent',
      'MapLayout',
      'MediaLibraryEntry',
      'MicrodataOrganization',
      'MigrationLog',
      'MiniBook',
      'MiniBookRoute',
      'MiniCruiseAndPackageProductPage',
      'MinibookFerryRoute',
      'MinibookProductList',
      'MinibookProductListItem',
      'MinibookProductListSection',
      'MinibookRouteCategory',
      'MultiroutePage',
      'NewsArticle',
      'NewsArticleList',
      'NewsletterSubscribeBlock',
      'NewsletterUnsubscribeBlock',
      'OpenGraphSettings',
      'PageContentAccordionList',
      'PageContentTextSection',
      'PageContentTextWithLanguageSwitcher',
      'PageLocalisationWrapper',
      'PageTagging',
      'PassengerCovidBanner',
      'PaxHeroContent',
      'PaxHeroLayout',
      'PaxProductCard',
      'PaxProductCardIcon',
      'PaxTimeTable',
      'PeopleChart',
      'ProductCategoryPage',
      'PromoBlock',
      'PromoContent',
      'Region',
      'Robots',
      'Route',
      'RoutePage',
      'RoutePdFs',
      'SeoFaq',
      'SeoFaqAnswer',
      'SeoFaqSnippet',
      'ShareChartSet',
      'SharePriceBlock',
      'SiteSettings',
      'SocialMediaLink',
      'SocialMediaLinkList',
      'StackingIconBox',
      'SubscriptionManagementSettings',
      'Tag',
      'TerminalCode',
      'TerminalLocation',
      'Terminals',
      'TextAndMediaContent',
      'TextAndMediaLayout',
      'TextContent',
      'Ticker',
      'TourOperatorWidget',
      'TravelGuidePage',
      'TripAtAGlance',
      'TripAtAGlanceDay',
      'TripAtAGlanceEntry',
      'Trustpilot',
      'TwitterFeed',
      'TwitterWidget',
      'Url',
      'UrlRedirect',
      'UrlSlug',
      'XSpaceRefComponent',
    ],
    FerryCrossingOnboardExperienceOverviewPageMiniBookReference: [
      'MiniBook',
      'MinibookProductList',
    ],
    FerryCrossingOnboardExperienceRelatedPageMiniBookReference: [
      'MiniBook',
      'MinibookProductList',
    ],
    FerryCrossingProductPageMiniBookReference: [
      'MiniBook',
      'MinibookProductList',
    ],
    FerryCrossingProductPageTimeTableReference: [
      'PaxTimeTable',
      'TextAndMediaContent',
    ],
    FreightScheduleContentScheduleTable: [
      'FreightScheduleTableDaily',
      'FreightScheduleTableFrequent',
      'FreightScheduleTableSeasonal',
    ],
    GenericPageContentRowsItem: ['ContentRow', 'LocalizedRow'],
    GenericPageMiniBookReference: ['MiniBook', 'MinibookProductList'],
    GenericPageSideArmItem: [
      'ContactBox',
      'FormForgetMe',
      'IframeBlock',
      'InfoBlock',
      'PageContentTextSection',
      'PromoBlock',
      'TextAndMediaLayout',
      'XSpaceRefComponent',
    ],
    HazardousCargoLinksItem: ['AssetLink', 'Link', 'Url'],
    HomePageHeroReference: ['PaxHeroLayout'],
    HomePageOptionalTextAndMedia: ['TextAndMediaContent'],
    InfoContentLinksItem: ['AssetLink', 'Link', 'Url'],
    LinkboxCarouselItemAppDetail: [
      'ExperienceCardContent',
      'TextAndMediaContent',
    ],
    LinkboxCarouselItemLink: ['AppLink', 'Link', 'Url'],
    LiveFeedPageContentReferencesItem: ['TextAndMediaContent', 'TextContent'],
    LiveQuarterlyReportContentRowsItem: [
      'PageContentTextSection',
      'TextAndMediaLayout',
    ],
    LocalisationWrapperLocalisedContentReference: [
      'EmailSenderCopyTemplate',
      'FormContactUs',
      'FormForgetMe',
      'FormGroupBooking',
      'HazardousCargo',
      'NewsArticle',
      'NewsArticleList',
      'NewsletterSubscribeBlock',
      'NewsletterUnsubscribeBlock',
      'PassengerCovidBanner',
    ],
    LocalizedRowLinkToEntry: ['LinkboxCarousel', 'PageContentAccordionList'],
    MiniCruiseAndPackageProductPageMiniBookReference: [
      'MiniBook',
      'MinibookProductList',
    ],
    MiniCruiseAndPackageProductPageTripAtAGlanceReference: [
      'PaxTimeTable',
      'TextAndMediaContent',
      'TripAtAGlance',
    ],
    MultiroutePageMapsAndContactBlocksItem: ['MapLayout', 'TextAndMediaLayout'],
    MultiroutePageOurServicesBlocksItem: ['InfoBlock', 'TextAndMediaLayout'],
    MultiroutePageServiceChargeBlocksItem: ['BafChargeBlock', 'InfoBlock'],
    PageContentTextWithLanguageSwitcherLinkItem: ['AssetLink', 'Link', 'Url'],
    PageContentTextWithLanguageSwitcherLinkLanguage2Item: [
      'AssetLink',
      'Link',
      'Url',
    ],
    PageLocalisationWrapperLocalisedContentReference: [
      'CabinPage',
      'CountryPage2Gmr',
      'FerryCrossingOnboardExperienceOverviewPage',
      'FerryCrossingOnboardExperienceRelatedPage',
      'FerryCrossingProductPage',
      'GenericPage',
      'HomePage',
      'MiniCruiseAndPackageProductPage',
      'ProductCategoryPage',
      'RoutePage',
      'Terminals',
      'TravelGuidePage',
    ],
    PaxHeroContentCtaButton: ['Link', 'Url'],
    PaxTimeTableLink: ['AssetLink', 'Url'],
    PromoBlockPromoContent: ['NewsArticle', 'PromoContent'],
    PromoContentLink: ['AssetLink', 'Link', 'Url'],
    RoutePageOurServicesTextAndMediaBlocksItem: [
      'PageContentTextSection',
      'TextAndMediaLayout',
    ],
    ShareChartSetLink: ['AssetLink', 'Link', 'Url'],
    TerminalsRowsItem: ['MapLayout', 'PageContentAccordionList'],
    TextAndMediaContentLinksItem: ['AppLink', 'AssetLink', 'Link', 'Url'],
    TextContentLinksItem: ['AssetLink', 'Link', 'Url'],
    TravelGuidePageMiniBookReference: ['MiniBook', 'MinibookProductList'],
    TravelGuidePageOptionalTextAndMediaContentReferencesItem: [
      'TextAndMediaContent',
      'TextAndMediaLayout',
    ],
    UrlSlugPage: [
      'CabinPage',
      'CountryPage2Gmr',
      'FerryCrossingOnboardExperienceOverviewPage',
      'FerryCrossingOnboardExperienceRelatedPage',
      'FerryCrossingProductPage',
      'FootballTeamPage',
      'GenericPage',
      'HomePage',
      'LibraryPage',
      'LiveFeedPage',
      'LiveQuarterlyReport',
      'MiniCruiseAndPackageProductPage',
      'MultiroutePage',
      'NewsArticle',
      'PageLocalisationWrapper',
      'ProductCategoryPage',
      'RoutePage',
      'Terminals',
      'TravelGuidePage',
    ],
    _Node: [
      'AccordionListItem',
      'AppLink',
      'AppRoute',
      'AppTerminalInfo',
      'AssetLink',
      'BafCharge',
      'BafChargeBlock',
      'BafChargesList',
      'BioPromo',
      'BusinessUnit',
      'CabinPage',
      'ChartSet',
      'ContactBox',
      'ContactContent',
      'ContentRow',
      'CountryPage2Gmr',
      'DropdownEntry',
      'EmailSenderCopyTemplate',
      'EmailTemplate',
      'EnquiryFormLocation',
      'ExperienceCardContent',
      'ExperienceCardFeature',
      'ExternalWidget',
      'FaqSection',
      'FerryCrossingOnboardExperienceOverviewPage',
      'FerryCrossingOnboardExperienceRelatedPage',
      'FerryCrossingProductPage',
      'FilterLocale',
      'FootballCalendar',
      'FootballMatch',
      'FootballSchedule',
      'FootballTeam',
      'FootballTeamPage',
      'FormContactUs',
      'FormForgetMe',
      'FormGroupBooking',
      'FreightArrivalsAndDepartures',
      'FreightRouteList',
      'FreightRoutesListBox',
      'FreightSchedule',
      'FreightScheduleContent',
      'FreightScheduleList',
      'FreightScheduleTableDaily',
      'FreightScheduleTableFrequent',
      'FreightScheduleTableSeasonal',
      'FreightSeasonSchedule',
      'GdprCheckboxLabel',
      'GdprSettings',
      'GeneralShortText',
      'GenericPage',
      'GhsPictogram',
      'HazardousCargo',
      'HomePage',
      'IconBlock',
      'IconBlockList',
      'IconListIcon',
      'IframeBlock',
      'InfoBlock',
      'InfoContent',
      'LibraryPage',
      'LightboxWrapper',
      'LineChart',
      'Link',
      'LinkboxCarousel',
      'LinkboxCarouselItem',
      'LinkboxItemContent',
      'LiveFeedPage',
      'LiveQuarterlyReport',
      'LocalisationWrapper',
      'LocalizedRow',
      'LoyaltyBenefits',
      'MapBlock',
      'MapContent',
      'MapLayout',
      'MediaLibraryEntry',
      'MicrodataOrganization',
      'MigrationLog',
      'MiniBook',
      'MiniBookRoute',
      'MiniCruiseAndPackageProductPage',
      'MinibookFerryRoute',
      'MinibookProductList',
      'MinibookProductListItem',
      'MinibookProductListSection',
      'MinibookRouteCategory',
      'MultiroutePage',
      'NewsArticle',
      'NewsArticleList',
      'NewsletterSubscribeBlock',
      'NewsletterUnsubscribeBlock',
      'OpenGraphSettings',
      'PageContentAccordionList',
      'PageContentTextSection',
      'PageContentTextWithLanguageSwitcher',
      'PageLocalisationWrapper',
      'PageTagging',
      'PassengerCovidBanner',
      'PaxHeroContent',
      'PaxHeroLayout',
      'PaxProductCard',
      'PaxProductCardIcon',
      'PaxTimeTable',
      'PeopleChart',
      'ProductCategoryPage',
      'PromoBlock',
      'PromoContent',
      'Region',
      'Robots',
      'Route',
      'RoutePage',
      'RoutePdFs',
      'SeoFaq',
      'SeoFaqAnswer',
      'SeoFaqSnippet',
      'ShareChartSet',
      'SharePriceBlock',
      'SiteSettings',
      'SocialMediaLink',
      'SocialMediaLinkList',
      'StackingIconBox',
      'SubscriptionManagementSettings',
      'Tag',
      'TerminalCode',
      'TerminalLocation',
      'Terminals',
      'TextAndMediaContent',
      'TextAndMediaLayout',
      'TextContent',
      'Ticker',
      'TourOperatorWidget',
      'TravelGuidePage',
      'TripAtAGlance',
      'TripAtAGlanceDay',
      'TripAtAGlanceEntry',
      'Trustpilot',
      'TwitterFeed',
      'TwitterWidget',
      'Url',
      'UrlRedirect',
      'UrlSlug',
      'XSpaceRefComponent',
    ],
  },
}
